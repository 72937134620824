<template>
  <app-form-select
    v-model="model"
    hint="Preferred language will be used for transactional emails"
    item-title="label"
    item-value="value"
    :items="items"
    label="Preferred language"
    :return-object="false"
  />
</template>

<script lang="ts" setup>
const model = defineModel<string>();

const items: { label: string; value: string }[] = [
  {
    label: "French",
    value: "fr",
  },
  {
    label: "English",
    value: "en",
  },
];
</script>
